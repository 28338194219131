@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Sansation_Regular;
    src: url("../public/fonts/Sansation_Regular.ttf");
    font-display: swap;
  }

  @font-face {
    font-family: Sansation_Bold;
    src: url("../public/fonts/Sansation_Bold.ttf");
    font-display: swap;
  }

  @font-face {
    font-family: Sansation_Light;
    src: url("../public/fonts/Sansation_Light.ttf");
    font-display: swap;
  }


@font-face {
  font-family: 'DanaBlack';
  src: url('../public/fonts/Dana-Black.woff2');
  font-display: swap;
}


@font-face {
  font-family: 'DanaBold';
  src: url('../public/fonts/Dana-Bold.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Dana_Regular';
  src: url('../public/fonts/Dana-Regular.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'DanaThin';
  src: url('../public/fonts/Dana-Thin.woff2');
  font-display: swap;
}

  /* html,body,button, input, optgroup, select, textarea {
    font-family: Sansation_Regular;
  } */

  *[dir='ltr'] {
    @apply font-sansation-r !important;
  }
 
 *[dir='rtl'] {
    @apply font-dana-r !important;
  }
}

@layer components {
  .btn-primary {
    @apply bg-blue text-white
  }
  .btn-primary:hover{
    @apply bg-blue-2
  }
  .btn-secondary {
    @apply bg-orange hover:bg-orange text-white
  }
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
  }
}


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  background-color: #F2F2F2 !important;
  position: relative;
  z-index: -10;
  scroll-behavior: smooth;
}

.swiper-button-prev,
.swiper-button-next {
  display: none !important;
}

#nprogress .bar {
  background: #00A3FF !important;
}
#nprogress .peg {
  box-shadow: 0 0 10px #00A3FF, 0 0 5px #00A3FF !important;
}

#nprogress .spinner{
  display: none !important;
}

#goftino_w .widget-icon{
  display: none;
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
    display: none;
}